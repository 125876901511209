import React, { useEffect , useRef } from 'react'
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import useNavigate from '@/hooks/useNavigate'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";


const ProBox = ({ intro }) => {
    gsap.registerPlugin(ScrollTrigger)
   
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const proIntroRef = useRef()
    const proBoxRef = useRef()
    const {
        background_mobile,
        background,
        pro_name,
        text_id,
        learn_link,
        learn_link_out,//如果learnlink是外链 则不用useNavigate
        buy_link,
        pro_key,
        buy_link_out, //buylink 则不用useNavigate
        theme
    } = intro

    const goLearnLink = () => {
       
        if (!!learn_link_out) {
            window.open(learn_link)
        } else {
            useNavigate(learn_link)
        }
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 20066,
                product_name:intro.pro_key
            }
        });
    }

    const goBuyLink = () => {
       
        if (!!buy_link_out) {
            window.open(buy_link)
        } else {
            useNavigate(buy_link)
        }
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 20067,
                product_name:intro.pro_key
            }
        });
    }

    useEffect(() => {
        if(pro_key=='beampro'){
            gsap.to(proIntroRef.current, {
                opacity: 1,
                bottom:'64px',
                duration:0.6,
              
            })
        }else{
            gsap.to(proIntroRef.current, {
                opacity: 1,
                bottom:'64px',
                duration:0.5,
                scrollTrigger: {
                    start: "bottom bottom",
                    end: "bottom bottom",
                    trigger: proBoxRef.current,
    
                }
            })
        }
       

    }, [])

    return (
        <div className={style.probox} ref={proBoxRef}>
            <div className={style.proboxImg} style={{ backgroundImage: `url(${ifPc ? background : background_mobile})`,cursor:!!learn_link?'pointer':'auto' }} onClick={()=>{
                !!learn_link&&goLearnLink()
            }}></div>
            <div className={style.proboxContent} 
                 ref={proIntroRef}
                 style={{color:theme}}
            >
                <div className={style.proboxContentTitle}>{pro_name}</div>
                <div className={style.proboxContentDesc}>
                    {intl.formatMessage({ id: text_id })}
                </div>
                <div className={style.proboxContentBtn}>
                    {!!learn_link && <div className={style.proboxContentBtnItem}
                        onClick={goLearnLink}
                    >
                        {intl.formatMessage({ id: "learn_more" })}
                    </div>}
                    {!!buy_link && <div className={style.proboxContentBtnItem}
                        onClick={goBuyLink}
                    >
                        {intl.formatMessage({ id: "buy_now" })}
                    </div>}
                </div>
            </div>
        </div>
    )
}
export default ProBox