import React from 'react'
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import { XButton } from '@/components/index'
import { navigate } from 'gatsby'

const home_jp_stores_pc = `${process.env.resourceUrl}images/home/home_jp_store_list_pc.jpg`
const home_jp_stores_mobile = `${process.env.resourceUrl}images/home/home_jp_store_list_mobile.jpg`
const JPStores = () => {
    const ifPC = useBigScreen(768)

    return (
        <div className={style.section} style={{ backgroundImage: `url(${ifPC ? home_jp_stores_pc : home_jp_stores_mobile})` }}>
            <div className={style.content}>
                <div className={style.contentTitle}>XREAL体験店舗<br />全国展開</div>
                <div className={style.btn} onClick={() => {
                    navigate('/jp/air/stores');
                    typeof sensors !== "undefined" && sensors.track('module_interaction', {
                        custom: {
                            module_id: 20063
                        }
                    });
                }}>
                    体験店舗リスト
                </div>
             
            </div>
        </div>
    )
}

export default JPStores