import React from "react";
import useBigScreen from "@/hooks/bigScreen"
import { navigate } from "gatsby";


const JPTravel2024 = () => {
    const ifPc = useBigScreen(768)
    const travel2024_entry = `${process.env.resourceUrl}images/campaign/travel2024_entry.jpg`
    const travel2024_entry_mobile = `${process.env.resourceUrl}images/campaign/travel2024_entry_mobile.jpg`

    return (
        <img src={ifPc?travel2024_entry:travel2024_entry_mobile} style={{cursor:'pointer',width:'100%'}} onClick={()=>navigate('/jp/campaign/travel2024')}/>
    )
}

export default JPTravel2024